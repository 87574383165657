.table-container {
    height: auto !important;
    max-height: 100vh !important;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__heading {
        height: 5%;
        width: 100%;
    }

    &__table {
        display: flex;
        flex-direction: column;
        height: 95%;
        width: 100%;

        &__heading {
            height: 10%;
            width: 100%;

            &__row {
                height: 2rem;        

                &__element {
                    padding: 10rem;

                    &--small {
                        height: 100%;
                        width: 20rem;
                    }
                    &--medium {
                        height: 100%;
                        width: 40%;
                    }
                }
            }
        }
        &__body {
            height: 95%;
            width: 100%;

            &__row__content {
                width:100%;
                
                &__element {

                    &--small {
                        width: 20%;
                        max-height: 20rem;
                    }

                    &--medium{
                        height: 3rem;
                        width: 40rem;

                        &__div{
                            max-height: 20rem;
                            overflow-y: auto;

                            &__span {
                                white-space: pre;
                            }
                        }
                    }
                }
            }
        }
    }
}