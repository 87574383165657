.active {
    border-bottom: 1px solid black !important;
}

.pagination-items {
    padding: 0 0.1rem;
    display: flex;
    box-sizing: border-box;

    &__list {
        box-sizing: content-box;
        padding: 0;
        display: flex;
        align-items: center;
        list-style: none;

        &__item {
            cursor: pointer;
            min-width: 1.4rem;
            width: auto;
            text-align: center;
            border-bottom: 1px solid transparent;
            transition: all .5s ease-in-out;
            box-sizing: content-box;

            &:not(:first-child) {
                margin-left: .3rem;
            }
            &:hover {
                border-bottom: 1px solid black;
            }

            &__text {
                font-size: 1.1rem;
            }
        }
    }
}