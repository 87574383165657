.search-bar {
    padding: 0;
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        width:40%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5rem;
        position: relative;

        &__icon {
            margin: -2px 5px -3px 0;
            padding: 0.1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5rem;
            position: absolute;
            right: 0;
        }

        &__input {
            width: 100%;
            height: 100%;
            margin: 0;

            &__element {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding: 0 2rem;
                border: 0;
                border-radius: 5rem;
                &::placeholder {
                text-align: center;
                }
            }
        }
    }
}