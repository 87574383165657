@import "src/theme";

.modal-copy-box {
  margin: 0.5em 0;
  //max-width: 100%;

  .pre {
    font-family: monospace;
    border: $light-gray 1px solid;
    padding: 0.5em;
    margin-top: 0;
    margin-bottom: 0;

    white-space: pre-wrap;
    word-break: break-all;
  }
}

.modal-copy-button {
  margin-top: calc((2em - 24px) / 2);
  color: $light-gray;
}

.modal-copy-button:hover {
  color: $dark-gray;
}

.modal-copy-button:active {
  color: black;
}