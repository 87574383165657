@import "theme";

body, html {
  background-color: $body-bg;
  padding: 0;
  margin: 0;
}

$logo-cell-height: 85px;

#App {
  display: grid;
  grid-template-columns: [top] auto [bottom] minmax(0, 1fr);
  grid-template-rows: [left] $logo-cell-height [right] minmax(0, 1fr);
  grid-template-areas:
    "logo topbar"
    "sidebar content";

  align-items: center;

  height: 100vh;
  width: 100vw;
}

#grid-logo {
  grid-area: logo;
  //place-self: center;
  padding: 22px;
  //height: $logo-cell-height;

  background-color: white;
}

#grid-sidebar {
  grid-area: sidebar;
  align-self: stretch;

  background-color: white;
  padding: 0 20px 20px 20px;
}

#grid-topbar {
  grid-area: topbar;
  place-self: center stretch;
}

#grid-content {
  grid-area: content;
  align-self: start;

  height: 100%;
  width: 100%;
  overflow: auto;
}


// Modal
#modal {
  z-index: $modal-z;

  background-color: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .modal-content {
    padding: 1em;
    min-width: 33%;
    max-width: 75%;
    max-height: 90%;

    border-radius: 10px;
    background: $white;
    box-shadow: 5px 5px 10px black;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .inner-content {
      width: 100%;
      overflow-y: auto;
    }

    p {
      margin: 0;
    }
  }

  .modal-title {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
  }
}

// Notifications
#notifications {
  z-index: $notif-z;

  position: fixed;
  top: 2em;
  right: 2em;

  min-width: 25%;
  max-width: 40%;
  height: auto;

  background-color: transparent;

}