// Colors
//$blue: #5551ffff;
$blue: #3C3ACD;
$red: #ef476f; //rgb(255, 65, 89);
$yellow: #ffd166; //rgb(255, 181, 60);
$green: #06d6a0; //rgb(29, 207, 220);
$light-gray: rgb(200, 200, 200);
$medium-gray: #a1a0a0;
$dark-gray: rgb(121, 119, 119);
$body-bg: rgb(245, 246, 249);
$white: #fff;

// Z-index
$modal-z: 100;
$notif-z: 200;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}