@import "src/index";

.sidebar {
  height: 100%;

  a {
    text-decoration: none;
  }
}

.sidebar-icon {
  width: 1.5em;
  height: 1.5em;
  //margin-right: 1em;
}

.sidebar-text {
  margin-left: 1em;
  font-size: 16px;
  line-height: 1em;
  font-weight: 500;

  //overflow: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                         supported by Chrome, Edge, Opera and Firefox */

}

.sidebar-item {
  background-color: $white;
  border-radius: 10px;

  width: auto;
  //padding: 0.5em 20px;
  padding: 0.5em 10px;

  color: $blue;
}

.sidebar-item:active {
  color: $dark-gray;
}

.sidebar-item[aria-selected], .active .sidebar-item {
  background-color: $blue;
  color: $white;
}

.sidebar-item[aria-selected]:active, .active .sidebar-item:active {
  color: $light-gray;
}

.logo-container {
  background: white;
  height: 100%;
}

.logo {
  object-fit: cover;
  object-position: 0 0;
  height: 41px;
}