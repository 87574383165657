.pagination {
    // background-color: red;

    &__list {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &__item {
            cursor: pointer;
            &:not(:first-child) {
                margin-left: 0.5rem;
            }
        }

        &__input{
            margin-left: 0.5rem;
            min-width: 1rem;
            max-width: 1.5rem;
            text-align: center;
        }
    }
}