@import "src/theme";

.item-edit-page {
  padding: 0 20px 30px;

  .header {
    text-transform: uppercase;
    font-weight: 800;
  }
}

.group-link {
  color: $blue;
  text-decoration: none;
  margin-right: 6px;
}

.group-link:visited {
  color: $blue;
}

.group-link:active {
  color: $dark-gray;
}

.group-link:hover {
  color: $dark-gray;
}


.button {
  font-weight: 500;
  font-size: 16px;
  line-height: 1em;
  padding: calc((40px - 16px) / 2);
  min-width: 5em;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.button-save {
  background-color: $blue;
}

.button-reset {
  background-color: $dark-gray;
}

.button-delete {
  background-color: $red;
}