@import "src/theme";

.item-table {
  padding: 0 30px 30px;
  &__navigation {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    margin-bottom: 20px;

    .header-button, .header-title {
      font-weight: 800;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1em;
    }

    .header-button {
      text-decoration: none;
      color: black;
    }

    .header-button:hover {
      color: $blue;
    }
  }

  table {
    border-radius: 10px;
    background: white;

    border-spacing: 0.5em 0.5em;
    margin: -0.5em;

    td {
      font-weight: 300;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    tr {
      overflow-x: scroll;
    }

    tr:hover {
      color: $blue;
    }

    thead tr:hover {
      color: inherit;
    }

    td:last-child {
      overflow: hidden;
      max-width: 1px;
      width: 100%;
    }
  }

  .table-header {
    .header-cell {
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;

      text-align: left;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .header-arrows {
      padding-left: 5px;
    }

    .header-arrows * {
      border: solid $medium-gray;
      border-width: 0 2px 2px 0;
    }

    .header-arrows *[aria-selected="true"] {
      border: solid $dark-gray;
      border-width: 0 2px 2px 0;
    }

    .header-arrows .arrow-down {

      display: inline-block;

      width: 0px;
      height: 0px;

      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    .header-arrows .arrow-up {
      display: inline-block;

      width: 0px;
      height: 0px;

      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
}