@import "src/theme";

.icon-button-bg {
  height: 1.5em;
  width: 1.5em;
  border-radius: 2em;
}

.icon-button-icon {
  //width: 1.1em;
  //height: 1.1em;
  color: $white;
}

.icon-button-icon-div {
  display: flex;
}

.icon-color-green {
  background-color: $green;
}

.icon-color-yellow {
  background-color: $yellow;
}

.icon-color-red {
  background-color: $red;
}

.icon-color-gray {
  background-color: $dark-gray;
}

.icon-button-spin .icon-button-icon {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}