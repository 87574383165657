@import "src/theme";


.topbar {
  padding: 0 20px;

  color: $dark-gray;
}

.topbar .feather {
  margin-right: 0.25em;
}

.search-box {
  background: $white;
  border-radius: 10px;

  padding: 0 10px;
  width: 33%;
  min-width: 25em;

  svg {
    height: 20px;
  }

  input {
    border: none;
    color: $dark-gray;
    font-size: 16px;
    outline: none;
    height: 36px;
  }
}

.search-box input::placeholder {
  color: $dark-gray;
  opacity: 1;
}