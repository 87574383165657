@import "src/theme";

.notification-container {
  border-radius: 10px;
  color: $white;
  padding: 0.75em 1em;

  box-shadow: 5px 5px 10px $dark-gray;
}

.notification-type-info {
  background-color: $green;
  color: black;
}

.notification-type-warning {
  background-color: $yellow;
  color: black;
}

.notification-type-error {
  background-color: $red;
  color: black;
}

.notification-close {
  width: 1.3em;
  height: 1.3em;
}

.notification-title {
  font-weight: 500;
  text-transform: capitalize;
}

.notification-content {
  font-weight: 300;
  margin: 0;
  padding: 0;
}