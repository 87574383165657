@import "src/theme";


.section {
  padding: 0 20px 0.5em 20px;
  margin-bottom: 20px;

  .title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    height: 16px;

    margin-bottom: 8px;
  }

  .columns {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 0.5em;

    .empty {
      font-size: 16px;
      font-weight: 200;
      color: $medium-gray;
      margin-top: 10px;
    }
  }

  .column {
    align-self: flex-start;
  }
}