.table-container {
    display: flex;
    height: auto !important;
    max-height: 32vh !important;
    margin: 1rem 0  !important;

    &__heading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10% !important;
    }

    &__table {
        height: 80% !important;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &__heading {
            height: 10%;

            &__row {
                width: 100% !important;
                display: flex;
                &__element {
                    &--medium {
                        text-align: center;
                        width: 33.33% !important;
                    }
                }
            }
        }

        &__body {
            width: 100%;
            height: 90% !important;

            &__row {
                width: 100% !important;
                display: flex;

                &__element {
                    &--medium {
                        text-align: center;
                        width: 33.33% !important;
                    }
                }
            }
        }
    }
    &__pagination {
        height: 10%;
        width: 100%;
    }
}